import React from 'react';
import {Navbar, Nav, Button, Image, Badge} from 'react-bootstrap';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import {GoSignIn, GoSignOut} from 'react-icons/go';
import {Auth} from '@aws-amplify/auth';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Footer from '../components/Footer';
import NotFound from './NotFound';
import { Base64 } from 'js-base64';

class MainView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuExpanded: false
    }
    this.toggle = this.toggle.bind(this);
    this.signOut = this.signOut.bind(this);
    this.signIn = this.signIn.bind(this);
  }


  async signOut() {
    await Auth.signOut()
    .then(() => {
      localStorage.setItem('state', Base64.encode(window.location.href));
    })
    .catch((error) => {
      console.error(error);
    });
  }
  
  async signIn() {
    const url = window.location.href;
    let hostedUI = `https://auth.beluga.fyi/login?client_id=6h7j09uukij26bpfpbnk7p6atc&response_type=code&scope=email+openid&redirect_uri=https%3A%2F%2Fbeluga.fyi%2F`;
    if (url !== 'https://beluga.fyi/') {      
      const state = Base64.encode(url);
      hostedUI = `${hostedUI}&state=${state}`
      
      if (window.location.hostname === 'localhost') {
        hostedUI = `https://auth.beluga.fyi/login?client_id=6h7j09uukij26bpfpbnk7p6atc&response_type=code&scope=email+openid&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2F&state=${state}`;
      }
    }
    window.location.replace(hostedUI);
  }
  
  toggle() {
    this.setState({ menuExpanded: !this.state.menuExpanded })
  }

  render () {
    const {user, unreadUserNotifications} = this.props;

    const userIcon = (<div className='userInfoButton'>
        {unreadUserNotifications && unreadUserNotifications.length > 1 && <Badge bg='danger' className='userNotifications'>{unreadUserNotifications.length}</Badge>}
        <img
          src={`https://ui-avatars.com/api/name=${user  ? user.displayUsername : ''}&background=random`}
          alt='userIcon'
          className='imgdefault'
        /> 
    </div>);
    return (
        <div className='main-wrapper'>
          <div className='content-wrap'>
            <BrowserRouter>
              <Navbar bg='light'>
                <Navbar.Brand href='/'>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  <Image src='/LogoNoTagNoBuffer.png' width={60}/>
                </Navbar.Brand>
                  <Nav className='container-fluid'>
                    {
                      this.props.routes
                        .filter((route) => route.visible)
                        .map((route) => {
                          return (
                            <LinkContainer to={route.path} key={route.path}>
                              <Nav.Link>
                                {
                                  route.icon &&
                                  <route.icon />
                                }
                                <span>&nbsp;</span>
                                {route.name}
                              </Nav.Link>
                            </LinkContainer>
                          );
                        })
                    }
                    {
                      user &&
                      <Nav.Item className='ms-auto'>
                          <NavDropdown title={userIcon} flip align='end'>
                            <NavDropdown.Item disabled title='This random username will help keep you anonymous.'>Hello, {user.displayUsername}</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href='/'>Home</NavDropdown.Item>
                            <NavDropdown.Item href='/saved'>Saved Homes</NavDropdown.Item>
                            <NavDropdown.Divider />
                              <Button variant='link' onClick={this.signOut}>
                                <GoSignOut />
                                <span>&nbsp;</span>
                                  Sign Out
                              </Button>
                        </NavDropdown>
                      </Nav.Item>
                  }
                  
                    {
                      user === undefined &&
                      <Nav.Item className='border-left pl-2 ms-auto'>
                        <Button variant='link' onClick={this.signIn} className='mr-auto'>
                          <GoSignIn />
                          <span>&nbsp;</span>
                          Sign In
                        </Button>
                    </Nav.Item>
                  }
                  </Nav>
              </Navbar>
              <Routes>
                {
                  this.props.routes.map((route) => {
                    return (
                      <Route key={route.name} path={route.path} element={<route.component user={user} setUser={this.props.setUser} signIn={this.signIn}/>}/>
                    );
                  })
                }
                <Route path='*' exact={true} element={<NotFound user={user} setUser={this.props.setUser} signIn={this.signIn}/>} />
              </Routes>
            </BrowserRouter>
          </div>
          <Footer />
        </div>
    )
  }
}

export default MainView