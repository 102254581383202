import React from 'react'

const NoComments = () => {
  return (
    <div className='no-comDiv'>
      {' '}
      No comments here. Be the first one to comment!
    </div>
  )
}

export default NoComments