import './App.css';
import React from 'react';
import {Auth} from '@aws-amplify/auth';
import { Hub } from 'aws-amplify';
import MainView from './views/MainView';
import PropertyDetails from './views/PropertyDetails';
import SavedProperties from './views/SavedProperties';
import Home from './views/Home';
// import User from './views/User';
import { Amplify } from 'aws-amplify';
import config from './aws-configuration';
import '@aws-amplify/ui-react/styles.css';
import { redirectIfStateIsPresent, storeStateIfPresent } from './utils/utils';
import GraphQLClient from './clients/GraphQLClient';

Amplify.configure(config);
Hub.listen('auth', (data) => {
  switch(data.payload.event) {
    case 'cognitoHostedUI':
    case 'signIn':
    default:
    redirectIfStateIsPresent();
  }
})
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: undefined,
      unreadUserNotifications: []
    }
    this.authenticatedRoutes = [
      {
        path: '/',
        name: 'Home',
        description: 'Home',
        component: Home,
        visible: false
      },
     /* {
        path: '/account',
        name: 'My Account',
        description: 'User',
        component: User,
        visible: true
      },*/
      {
        path: '/saved',
        name: 'Saved Properties',
        description: 'Saved Properties',
        component: SavedProperties,
        visible: false
      },
      {
        path: '/details/:propertyId',
        name: 'Property Details',
        description: 'Property Details',
        component: PropertyDetails,
        visible: false
      },
    ];

    this.defaultRoutes = [
      {
        path: '/',
        name: 'Home',
        description: 'Home',
        component: Home,
        visible: false
      },
      {
        path: '/details/:propertyId',
        name: 'Property Details',
        description: 'Property Details',
        component: PropertyDetails,
        visible: false
      }
    ];
    if (window.location.hostname === 'localhost') {
      this.defaultRoutes.push(
        {
          path: '/saved',
          name: 'Saved Properties',
          description: 'Saved Properties',
          component: SavedProperties,
          visible: false
        }
      );
    }
    this.setUser = this.setUser.bind(this);
    this.getUnreadNotifications = this.getUnreadNotifications.bind(this);
    this.graphqlClient = new GraphQLClient();
  }

  setUser(user) {
    this.setState({user});
  }
  async componentDidMount() {
    // store but don't redirect (just yet). Give amplify time to store the cookies
    storeStateIfPresent();
    try {
      // todo, might be able to switch to a single call to currentSession instead of getting the user
      const user = await Auth.currentAuthenticatedUser();
      const session = await Auth.currentSession();
      const sub = session.getAccessToken().payload.sub;
      user.attributes = {};
      user.attributes.sub = sub;
      user.displayUsername = await this.graphqlClient.getUsernameData(sub);
      if (user !== null) {
        this.setState({user: user}, async ()=> {await this.getUnreadNotifications()})
      }
    } catch(error) {
      console.error(error)
    }
  }

  async getUnreadNotifications() {
    if (this.state.user !== undefined) {
      const unreadUserNotifications = this.graphqlClient.getUnreadNotifications(this.state.user.attributes.sub);
      this.setState({unreadUserNotifications});
    }
  }

  render() {
  const {user, unreadUserNotifications} = this.state;
  const routes = user !== undefined ? this.authenticatedRoutes : this.defaultRoutes;
    return (
      <div className='App'>
        <MainView routes={routes} user={user} setUser={this.setUser} unreadUserNotifications={unreadUserNotifications}/>
    </div>
    )
  }
}

export default App;
