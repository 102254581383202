import React from 'react';
import GraphQLClient from '../clients/GraphQLClient';
import CommentSection from './comments/index';
import {formatComments, formatIndividualComment} from '../utils/utils'

class Comments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      newComment: ''
    }
    this.graphQLClient = new GraphQLClient();
    this.postComment = this.postComment.bind(this);
    this.deleteComment = this.deleteComment.bind(this);
    this.getComments = this.getComments.bind(this);
    this.updateComment = this.updateComment.bind(this);
    this.handleCommentInput = this.handleCommentInput.bind(this);
  }

  async componentDidMount() {   
    this.setState({comments: formatComments(await this.getComments())});
  }

  async getComments() {
    const input = {
      gsi_pk: this.props.propertyId
    } 
    if (this.props.user) {
      return await this.graphQLClient.listCommentsWithCognitoUser(input)
    } else {
      return await this.graphQLClient.listCommentsWithApiKey(input)
    }
   
  }

  handleCommentInput(event) {
    event.preventDefault();
    this.setState({newComment: event.target.value});
  }

  async postComment(comment) {
    const input = {
      comment: comment.text,
      gsi_pk: this.props.propertyId,
      username: this.props.user.displayUsername
    }
    const response = await this.graphQLClient.postComment(input);
    const newComment = formatIndividualComment(response.data.createCommentsV0);
   
    this.setState({comments: [
      ...this.state.comments, newComment
    ]})
    // reset zoom
    try {
      window.document.body.style.zoom = 1;
    } catch(err) {
      console.error(err);
    }
    
  }

  async deleteComment(comment) {
    await this.graphQLClient.deleteComment(comment.comIdToDelete);
    this.setState({comments: this.state.comments.filter(c => c.comId !== comment.comIdToDelete)})
  }
  async updateComment(comment) {
    const commentToUpdate = this.state.comments.filter(c => c.comId === comment.comId)[0];
    commentToUpdate.comment = comment.text;
    await this.graphQLClient.updateComment(commentToUpdate);
  }

  render() {
    const {comments} = this.state;
    const user = this.props.user;
    const userLoggedIn = user !== undefined;
    return (
      <div>
        {userLoggedIn &&
          <CommentSection
            commentData={comments}
            onDeleteAction={this.deleteComment}
            onEditAction={this.updateComment}
            inputStyle={{innerHeight: 10000}}
            titleStyle={{color: '#6381a8'}}
            currentUser={{
              currentUserId: user.attributes.sub,
              currentUserImg: `https://ui-avatars.com/api/name=${user.displayUsername}&background=random`,
              currentUserFullName: user.displayUsername
            }}
            logIn={{
              signIn: () => {this.props.signIn()}
            }}
            onSubmitAction={(comment) => this.postComment(comment)}
          /> 
        }
        { !userLoggedIn &&
          <CommentSection
            commentData={comments}
            currentUser={null}
            titleStyle={{color: '#6381a8'}}
            logIn={{
              signIn: () => {this.props.signIn()}
            }}
            onSubmitAction={(comment) => this.postComment(comment)}
          />

        }

        
      </div>
    )
  }
}
export default Comments
