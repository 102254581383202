import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import GraphQLClient from '../clients/GraphQLClient';
import SearchBar from "../components/SearchBar";
import Comments from "../components/Comments";
import StreetView from "../components/StreetView";
import { withRouter } from "../utils/utils";
import { Auth } from 'aws-amplify';



class PropertyDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPropertySaved: false,
      property:{
        city: "",
        firstStreet: "",
        gsi_pk: "",
        lat: 0,
        lng: 0,
        pk: "1",
        prettyAddress: "",
        secondStreet: "",
        state: "",
        zipCode: 0,
      },
      havePropertyDetails: false
    }
    this.graphQLClient = new GraphQLClient();
    this.openSMS = this.openSMS.bind(this);
    this.savePropertyToggle = this.savePropertyToggle.bind(this);
    this.isPropertySaved = this.isPropertySaved.bind(this);
  }

  async componentDidMount() {
    const property =  await this.graphQLClient.getProperty(this.props.params.propertyId);
    if (property === undefined || property === null) {
      // redirect to not found
     this.props.navigate('/404');
    }
    // check if property is saved
    const isPropertySaved = await this.isPropertySaved(); 
    this.setState({property: property, havePropertyDetails: true, isPropertySaved});
  }
  
  async isPropertySaved() {
    let sub;
    if (this.props.user === undefined) {
      try {
        const session = await Auth.currentSession();
        sub = session.getAccessToken().payload.sub;
      } catch(e) {
        // no user
        return false;
      }
    } else {
      sub = this.props.user.attributes.sub;
    }
    return await this.graphQLClient.isPropertySavedByUser(sub, this.props.params.propertyId);
  }
  async savePropertyToggle() {
    if (this.props.user === undefined) {
      this.props.signIn();
    }
    if (this.state.isPropertySaved) {
      await this.graphQLClient.deletePropertyToUser(this.props.user.attributes.sub, this.props.params.propertyId);
      this.setState({isPropertySaved: false});

    } else {
      await this.graphQLClient.savePropertyToUser(this.props.user.attributes.sub, this.props.params.propertyId);
      this.setState({isPropertySaved: true});
    }
  }

  openSMS() {
    const smsBody = encodeURIComponent(`Check out this property: ${window.location.href}`);
    if(navigator.userAgent.match(/Android/i)){
            
      window.open(`sms://?body=${smsBody}`,'_blank');
        
   } else if(navigator.userAgent.match(/iPhone/i)){
           
    window.open(`sms://&body=${smsBody}`,'_blank');
     
   } else {
    // default to same as android for now
    window.open(`sms://?body=${smsBody}`,'_blank');
   }
  }

  render() {
    const {propertyId} = this.props.params;
    const { havePropertyDetails, isPropertySaved } = this.state;
    // handle null property (not found)
    const {prettyAddress, gsi_pk} = this.state.property;
    return (
      <Container>
        <SearchBar/>
        <Row style={{marginBottom: '2vh'}}>
          <Col className='col-12'>
            {'    '}<h1 style={{color: '#6381a8'}}>{prettyAddress}</h1>
          </Col>
        </Row>
        <br/>
        {havePropertyDetails && <Row>
          <StreetView urlAddress={gsi_pk}  isPropertySaved={isPropertySaved} savePropertyToggle={this.savePropertyToggle} shareProperty={this.openSMS}/>
        </Row>}
        <br/>
          <Comments propertyId={propertyId} {...this.props}/>
      </Container>
    )
  }
}

export default withRouter(PropertyDetails);