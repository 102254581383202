import React from 'react';
import {Container} from 'react-bootstrap';
import SearchBar from '../components/SearchBar';
import GraphQLClient from '../clients/GraphQLClient';
import { Card, Row } from 'react-bootstrap';


class SavedProperties extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        savedProperties: []
    }
    this.graphQLClient = new GraphQLClient();
    this.apiKey = 'AIzaSyCrbvygm7Lot0hl2swJyuApw902m4TLwF4';
    this.isStreetViewAvailable = this.isStreetViewAvailable.bind(this);
    this.addImageUrlToProperty = this.addImageUrlToProperty.bind(this);
  }
  async addImageUrlToProperty(property) {
    const showStreetView = await this.isStreetViewAvailable(property.gsi_pk);
      if (showStreetView === true) {
        property.imageUrl = `https://maps.googleapis.com/maps/api/streetview?location=${property.gsi_pk}&size=1800x1800&key=${this.apiKey}`;
      } else {
        property.imageUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${property.gsi_pk}&key=${this.apiKey}&size=1800x1800&maptype=satellite&zoom=20&markers=color:red%7Clabel:Home%7C${property.gsi_pk}`;
      }
      return property;
  }
  async componentDidMount() {
    //const savedProperties = [{gsi_pk: "414+arrowhead+dr+allen+tx", pk: "dea26dbc-cdf6-4234-8243-ec07e7871692", prettyAddress: "414 Arrowhead Dr Allen, TX  75002", zipCode: 75002}]
    const sub = this.props.user.attributes.sub;
    const savedProperties = await this.graphQLClient.listPropertySavedByUser(sub);
    const hydratedProperties = await Promise.all(savedProperties.map(property => this.addImageUrlToProperty(property)));

    this.setState({savedProperties: hydratedProperties});
  }
  async isStreetViewAvailable(location) {
    return fetch(`https://maps.googleapis.com/maps/api/streetview/metadata?size=600x300&location=${location}&key=${this.apiKey}`)
    .then(res => res.json())
    .then(jsonResponse => {return jsonResponse})
    .then(metadata => {return 'OK' === metadata.status});
  }
  render () {
    const {savedProperties} = this.state;
    return (
      <Container>
        <br/>
        <SearchBar/>
        <Row style={{justifyContent: 'center'}}>
        {savedProperties.map(property => {
          return (
            <Card style={{ width: '18rem', margin: '2rem', padding: '1rem' }} key={property.pk}>
              <Card.Img variant="top" src={property.imageUrl}/>
              <Card.Body>
                <Card.Title><a href={`https://beluga.fyi/details/${property.pk}`}>{property.prettyAddress}</a></Card.Title>
              </Card.Body>
            </Card>
          )
        })}
        </Row>
      </Container>
    )
  }
}

export default SavedProperties;
