export const getCommentsForProperty = `query ListCommentsV0s($gsi_pk: String) {
    listCommentsV0s(filter: {gsi_pk: {eq: $gsi_pk}}) {
      items {
        sk
        pk
        sub
        comment
        gsi_pk
        gsi_sk
        likeCount
        username
        dislikeCount
        datePosted
        updatedAt
      }
    }
  }`;

  export const getProperty = `query getProperty($pk: String!) {
    getProperty(pk: $pk) {
      city
      firstStreet
      gsi_pk
      lat
      lng
      pk
      secondStreet
      state
      zipCode
      prettyAddress
    }
  }`;

  export const getUser = `query getUser($pk: String!) {
    getUsersV0(pk: $pk) {
      gsi_pk
    }
  }`;

  export const getPropertySavedByUser = `query GetSavedPropertiesV0($pk: String!, $sk: String!) {
    getSavedPropertiesV0(pk: $pk, sk: $sk) {
      pk
    }
  }`;

  export const listPropertiesSavedByUser = `query ListSavedPropertiesV0s($gsi_pk: String!) {
    listSavedPropertiesV0s(gsi_pk: $gsi_pk) {
      items {
        gsi_pk
        zipCode
        pk
        prettyAddress
      }
      nextToken
    }
  }`;

  export const getTrendingProperties = `query MyQuery {
    getTrendingProperties(count: 10) {
      items {
        gsi_pk
        pk
        prettyAddress
        commentCountSk
      }
      nextToken
    }
  }`;

  export const getUserNotifications = `query getNotifications($userId: String!, $status: String) {
    getNotificationsForUser(userId: $userId, status: $status) {
      items {
        message
        pk
        propertyId
        sk
        status
        userId
      }
    }
  }`;