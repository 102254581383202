import { useState, useContext } from 'react'
import 'react-responsive-modal/styles.css'
 /* eslint-disable */
import { Modal } from 'react-responsive-modal'
import { GlobalContext } from '../../context/Provider'
import React from 'react'
import { Button } from 'react-bootstrap'


const DeleteModal = ({ comId, parentId }) => {
  const [open, setOpen] = useState(false)
  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)
  const globalStore = useContext(GlobalContext)

  return (
    <div>
      <div style={{ width: '100%' }} onClick={onOpenModal}>
        Delete
      </div>
      <Modal open={open} onClose={onCloseModal} center>
        <h2>Are you sure?</h2>
        <p>Once you delete this comment it will be gone forever.</p>
        <div className='deleteBtns'>
          <Button
            variant='danger'
            onClick={async () => (
              await globalStore.onDelete(comId, parentId),
              globalStore.onDeleteAction &&
                (await globalStore.onDeleteAction({
                  comIdToDelete: comId,
                  parentOfDeleteId: parentId
                }))
            )}
          >
            Delete
          </Button>
          <Button variant='secondary' onClick={onCloseModal}>
            Cancel
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default DeleteModal