import React from 'react';
import {Container} from 'react-bootstrap';
import SearchBar from '../components/SearchBar';

class NotFound extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <Container>
                <br/>
                <h1 style={{color: '#6381a8'}}>Whoops! We could not find that page! Try searching a property below!</h1>
                <SearchBar/>
            </Container>
        )
    }
}

export default NotFound;