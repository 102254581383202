import React from 'react';
import {Container, Image} from 'react-bootstrap';
import SearchBar from '../components/SearchBar';
import TrendingProperties from '../components/TrendingProperties';
import './style.css';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render () {
    return (
      <Container>
        <br/>
        <Image src='LogoNoTagNoBuffer.png' width='40%'/>
        <h4 className='logo-subtitle'>Look before you lease</h4>
        <SearchBar/>
        <br/>
        <TrendingProperties/>
      </Container>
    )
  }
}

export default Home;
