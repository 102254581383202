import React from 'react';
import { withRouter, getAllDropDownOptions } from "../utils/utils"
import * as SmartySDK from "smartystreets-javascript-sdk";
import GraphQLClient from '../clients/GraphQLClient';
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Spinner from 'react-bootstrap/Spinner';

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      suugestions: [],
      showSpinner: false
    }
    const SmartyCore = SmartySDK.core;
    const websiteKey = '160783181557952053';
    const smartySharedCredentials = new SmartyCore.SharedCredentials(websiteKey);
		const autoCompleteClientBuilder = new SmartyCore.ClientBuilder(smartySharedCredentials).withLicenses(["us-autocomplete-pro-cloud"]);
    this.SmartyCore = SmartyCore;
		this.autoCompleteClient = autoCompleteClientBuilder.buildUsAutocompleteProClient();
    this.graphQLClient = new GraphQLClient();

    this.handleOnSearch = this.handleOnSearch.bind(this);
    this.handleOnSelect = this.handleOnSelect.bind(this);
  }

  handleOnSearch(item) {
    if (item !== undefined && item !== '' && item.length > 4) {
      const lookup = new SmartySDK.usAutocompletePro.Lookup(item);
      lookup.maxResults = 10;
      this.autoCompleteClient.send(lookup)
			.then(results => {
				this.setState({suggestions: getAllDropDownOptions(results.result), searchString: item});
			})
    }
  }
  async handleOnSelect(item) {
    this.setState({showSpinner: true})
    const id = await this.graphQLClient.createProperty(item.firstStreet, item.secondStreet, item.city, item.state, item.zipCode);
    window.location.replace(`${window.location.origin}/details/${id}`);
  }


  render () {
    const {suggestions, showSpinner} = this.state;
    return (
      
    
      <div className='search-bar-container'>
        
          {showSpinner && <Spinner/>}
          {!showSpinner && <ReactSearchAutocomplete
              showNoResultsText={'Keep Typing...'}
              placeholder='Search any property, get the scoop.'
              fuseOptions={{
                ignoreLocation: true,
                threshold: 1.0
              }}
              items={suggestions}
              onSearch={this.handleOnSearch}
              onSelect={this.handleOnSelect}
              styling={{ zIndex: 4 }}
              autoFocus
            />}
      </div>
    )
  }
}

export default withRouter(SearchBar);
