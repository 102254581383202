import React from 'react';
import { Col } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faCheck, faArrowUpFromBracket, faHeart as faHeartSolid} from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartRegular } from '@fortawesome/free-regular-svg-icons';
import {isMobile} from 'react-device-detect';

class StreetView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      urlCopied: false
    }
    this.apiKey = 'AIzaSyCrbvygm7Lot0hl2swJyuApw902m4TLwF4';
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.isStreetViewAvailable = this.isStreetViewAvailable.bind(this);
}

async componentDidMount() {
  const metadata = await this.isStreetViewAvailable(this.props.urlAddress);
  const showStreetView = 'OK' === metadata.status;
  this.setState({showStreetView});
}
async isStreetViewAvailable(location) {
  return fetch(`https://maps.googleapis.com/maps/api/streetview/metadata?size=600x300&location=${location}&key=${this.apiKey}`)
  .then(res => res.json())
  .then(jsonResponse => {return jsonResponse});
}
copyToClipboard() {
  const url = window.location.href;
  navigator.clipboard.writeText(url);
  this.setState({urlCopied: true});
}
  render () {
    const {showStreetView, urlCopied} = this.state;

    const streetViewUrl = showStreetView === true ? `https://maps.googleapis.com/maps/api/streetview?location=${this.props.urlAddress}&size=1800x1800&key=${this.apiKey}` :
    `https://maps.googleapis.com/maps/api/staticmap?center=${this.props.urlAddress}}&key=${this.apiKey}&size=1800x1800&maptype=hybrid&zoom=19&markers=color:red%7Clabel:A%7C${this.props.urlAddress}`;
    return (
      <div style={{ justifyContent: 'center', display: 'flex'}}>
        <div className='image-wrapper'>
          <Image
            alt='Street View not available for this address'
            className='image-wrapper img'
            src={streetViewUrl} 
          />
           <div className='image-wrapper actions-div row'>
              <Col className={isMobile ?'col-4' : 'col-6'}>
                <button className='savePropertyBtn' onClick={this.props.savePropertyToggle}>
                  {this.props.isPropertySaved ?
                    <FontAwesomeIcon title='Save Property' icon={faHeartSolid} size='lg' style={{color: "#c42132"}} /> :
                    <FontAwesomeIcon title='Save Property' icon={faHeartRegular} size='lg' style={{color: "#c42132"}} />  
                  }
                </button>
              </Col>
              <Col className={isMobile ?'col-4' : 'col-6'}>
                <button className='savePropertyBtn' size='lg' onClick={this.copyToClipboard}>
                  <FontAwesomeIcon title='Copy URL' icon={urlCopied ? faCheck : faLink} size='lg' style={{color: urlCopied ? "#006600" : '#6381a8'}}/>{'  '}
                </button>
              </Col>
              {isMobile &&
              <Col className='col-4'>
                  <button className='savePropertyBtn'onClick={this.props.shareProperty}>
                    <FontAwesomeIcon title='Share via SMS' icon={faArrowUpFromBracket} size='lg' style={{ color: '#6381a8' }}/>{'  '}
                </button>
              </Col>
              }
           </div>
          </div>
      </div>
    )
  }
}

export default StreetView;