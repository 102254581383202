import React from 'react';
import { withRouter } from "../utils/utils"
import {Container} from 'react-bootstrap';
import GraphQLClient from '../clients/GraphQLClient';
import { Card, Row } from 'react-bootstrap';

class TrendingProperties extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        trendingProperties: []
    }
    this.GraphQLClient = new GraphQLClient();
    this.addImageUrlToProperty = this.addImageUrlToProperty.bind(this);
    this.isStreetViewAvailable = this.isStreetViewAvailable.bind(this);
    this.apiKey = 'AIzaSyCrbvygm7Lot0hl2swJyuApw902m4TLwF4';
  }

  async componentDidMount() {
    // get trending propertes
    const trendingProperties = await this.GraphQLClient.getTrendingProperties();
    const hydratedProperties = await Promise.all(trendingProperties.map(property => this.addImageUrlToProperty(property)));
    this.setState({trendingProperties: hydratedProperties});
  }

  async addImageUrlToProperty(property) {
    const showStreetView = await this.isStreetViewAvailable(property.gsi_pk);
      if (showStreetView === true) {
        property.imageUrl = `https://maps.googleapis.com/maps/api/streetview?location=${property.gsi_pk}&size=1800x1800&key=${this.apiKey}`;
      } else {
        property.imageUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${property.gsi_pk}&key=${this.apiKey}&size=1800x1800&maptype=satellite&zoom=20&markers=color:red%7Clabel:Home%7C${property.gsi_pk}`;
      }
      return property;
  }

  async isStreetViewAvailable(location) {
    return fetch(`https://maps.googleapis.com/maps/api/streetview/metadata?size=600x300&location=${location}&key=${this.apiKey}`)
    .then(res => res.json())
    .then(jsonResponse => {return jsonResponse})
    .then(metadata => {return 'OK' === metadata.status});
  }

  render () {
    const { trendingProperties } = this.state;
    return (
      <Container>
        {trendingProperties.length > 0 &&
        <div>
        <h1 style={{color: '#6381a8'}}>Trending Homes</h1>
        <br/>
        <Row style={{justifyContent: 'center'}}>
        {trendingProperties.map(property => {
          return (
            <Card style={{ width: '18rem', margin: '2rem', padding: '1rem' }} key={property.pk}>
              <a href={`https://beluga.fyi/details/${property.pk}`}><Card.Img variant="top" src={property.imageUrl}/></a>
              <Card.Body>
                <Card.Title><a href={`https://beluga.fyi/details/${property.pk}`}>{property.prettyAddress}</a></Card.Title>
                <Card.Text>{property.commentCountSk} {property.commentCountSk > 1 ? 'Comments' : 'Comment'}</Card.Text>
              </Card.Body>
            </Card>
          )
        })}
        </Row>
        </div>
        }
      </Container>
    
    )
  }
}

export default withRouter(TrendingProperties);
