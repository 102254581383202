 /* eslint-disable */
import React, { createContext, useEffect, useState } from 'react'
import _ from 'lodash'

export const GlobalContext = createContext({})

export const GlobalProvider = ({
  children,
  currentUser,
  replyTop,
  customImg,
  inputStyle,
  formStyle,
  submitBtnStyle,
  cancelBtnStyle,
  imgStyle,
  commentsCount,
  commentData,
  onSubmitAction,
  onDeleteAction,
  onReplyAction,
  onEditAction,
  currentData,
  replyInputStyle,
  removeEmoji,
  advancedInput
}) => {
  const [currentUserData] = useState(currentUser)
  const [data, setData] = useState([])
  const [editArr, setEdit] = useState([])
  const [replyArr, setReply] = useState([])

  useEffect(() => {
    if (commentData) {
      setData(commentData)
    }
  }, [commentData])

  useEffect(() => {
    if (currentData) {
      currentData(data)
    }
  }, [data])

  const handleAction = (id, edit) => {
    if (edit) {
      let editArrCopy = [...editArr]
      let indexOfId = _.indexOf(editArrCopy, id)
      if (_.includes(editArr, id)) {
        editArrCopy.splice(indexOfId, 1)
        setEdit(editArrCopy)
      } else {
        editArrCopy.push(id)
        setEdit(editArrCopy)
      }
    } else {
      let replyArrCopy = [...replyArr]
      let indexOfId = _.indexOf(replyArrCopy, id)
      if (_.includes(replyArr, id)) {
        replyArrCopy.splice(indexOfId, 1)
        setReply(replyArrCopy)
      } else {
        replyArrCopy.push(id)
        setReply(replyArrCopy)
      }
    }
  }

  const onSubmit = (text, uuid) => {
    let copyData = [...data]
    copyData.push({
      userId: currentUserData.currentUserId,
      comId: uuid,
      avatarUrl: currentUserData.currentUserImg,
      userProfile: currentUserData.currentUserProfile
        ? currentUserData.currentUserProfile
        : undefined,
      fullName: currentUserData.currentUserFullName,
      text: text,
      replies: []
    })
    setData(copyData)
  }

  const onEdit = (text, comId, parentId) => {
    let copyData = [...data]
    if (parentId) {
      const indexOfParent = _.findIndex(copyData, { comId: parentId })
      const indexOfId = _.findIndex(copyData[indexOfParent].replies, {
        comId: comId
      })
      copyData[indexOfParent].replies[indexOfId].text = text
      setData(copyData)
      handleAction(comId, true)
    } else {
      const indexOfId = _.findIndex(copyData, { comId: comId })
      copyData[indexOfId].text = text
      setData(copyData)
      handleAction(comId, true)
    }
  }

  const onReply = (
    text,
    comId,
    parentId,
    uuid
  ) => {
    let copyData = [...data]
    if (parentId) {
      const indexOfParent = _.findIndex(copyData, { comId: parentId })
      copyData[indexOfParent].replies.push({
        userId: currentUserData.currentUserId,
        comId: uuid,
        avatarUrl: currentUserData.currentUserImg,
        userProfile: currentUserData.currentUserProfile
          ? currentUserData.currentUserProfile
          : undefined,
        fullName: currentUserData.currentUserFullName,
        text: text
      })
      setData(copyData)
      handleAction(comId, false)
    } else {
      const indexOfId = _.findIndex(copyData, {
        comId: comId
      })
      copyData[indexOfId].replies.push({
        userId: currentUserData.currentUserId,
        comId: uuid,
        avatarUrl: currentUserData.currentUserImg,
        userProfile: currentUserData.currentUserProfile
          ? currentUserData.currentUserProfile
          : undefined,
        fullName: currentUserData.currentUserFullName,
        text: text
      })
      setData(copyData)
      handleAction(comId, false)
    }
  }

  const onDelete = (comId, parentId) => {
    let copyData = [...data]
    if (parentId) {
      const indexOfParent = _.findIndex(copyData, { comId: parentId })
      const indexOfId = _.findIndex(copyData[indexOfParent].replies, {
        comId: comId
      })
      copyData[indexOfParent].replies.splice(indexOfId, 1)
      setData(copyData)
    } else {
      const indexOfId = _.findIndex(copyData, { comId: comId })
      copyData.splice(indexOfId, 1)
      setData(copyData)
    }
  }

  return (
    <GlobalContext.Provider
      value={{
        currentUserData: currentUserData,
        replyTop: replyTop,
        data: data,
        handleAction: handleAction,
        editArr: editArr,
        onSubmit: onSubmit,
        onEdit: onEdit,
        replyArr: replyArr,
        onReply: onReply,
        onDelete: onDelete,
        customImg: customImg,
        inputStyle: inputStyle,
        formStyle: formStyle,
        submitBtnStyle: submitBtnStyle,
        cancelBtnStyle: cancelBtnStyle,
        imgStyle: imgStyle,
        commentsCount: commentsCount,
        onSubmitAction: onSubmitAction,
        onDeleteAction: onDeleteAction,
        onReplyAction: onReplyAction,
        onEditAction: onEditAction,
        replyInputStyle: replyInputStyle,
        removeEmoji: removeEmoji,
        advancedInput: advancedInput
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalProvider