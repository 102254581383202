import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Base64 } from 'js-base64';

export function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const params = useParams();
        const navigate = useNavigate();

        return <Component {...props} params={params} navigate={navigate}/>;
    }

    return ComponentWithRouterProp;
}

export function getAddressUniqueId(address) {
    const street = address.streetLine ? `${address.streetLine} ` : "";
    const secondary = address?.secondary ? `${address.secondary} ` : "";
    const entries = address?.entries !== 0 ? `(${address.entries}) ` : "";
    const city = address?.city ? `${address.city} ` : "";
    const state = address?.state ? `${address.state}` : "";

    return street + secondary + entries + city + state;
}

export function formatAutocompleteSuggestion(address) {
    getAddressUniqueId(address)
    const zip = address?.zipcode ? `, ${address.zipcode}` : "";

    return getAddressUniqueId(address)+ zip;
}
export function getAddressDropdownOption(address, i) {
    return {
        name: formatAutocompleteSuggestion(address),
        uniqueAddressId: getAddressUniqueId(address).replaceAll(' ', '+'),
        firstStreet: address.streetLine ? `${address.streetLine} ` : '',
        secondStreet: address?.secondary ? `${address.secondary} ` : '',
        city: address?.city ? `${address.city} ` : '',
        state: address?.state ? `${address.state}` : '',
        zipCode: address?.zipcode ? address.zipcode : '',
        id: i
    }
}

export function getAllDropDownOptions(addresses) {
    return addresses.map((address, i) => {
        return getAddressDropdownOption(address, i)
    });
}

export function formatComments(rawComments) {
    const formattedComments =  rawComments.map((raw) => {
        return formatIndividualComment(raw);
    });
    return formattedComments.sort(function(a, b) {
        var keyA = new Date(a.datePosted),
          keyB = new Date(b.datePosted);
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
}

export function formatIndividualComment(rawComment) {
    return {
        datePosted: rawComment.datePosted,
        updatedAt: rawComment.updatedAt,
        fullName: rawComment.username,
        userId: rawComment.sub,
        comId: rawComment.pk,
        text: rawComment.comment,
        type: rawComment.sk,
        avatarUrl: `https://ui-avatars.com/api/name=${rawComment.username}&background=random`
    }
}

export function redirectIfStateIsPresent() {
    const STATE = 'state';
    const state = localStorage.getItem(STATE);
      if (state !== null) {
        const url = Base64.decode(state);
        localStorage.removeItem(STATE);
        if (url !== '' && url !== undefined && url !== null) {
          window.location.replace(url);
        }
      }
}

export function storeStateIfPresent() {
    const STATE = 'state';
    const queryString = new URLSearchParams(window.location.search);
      if (queryString.has(STATE)) {
        const url = queryString.get(STATE);
        localStorage.setItem(STATE, url);
      }
}
