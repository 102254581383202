const config = {
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_O62rFUhp6',
    aws_user_pools_web_client_id: '6h7j09uukij26bpfpbnk7p6atc',
    aws_appsync_graphqlEndpoint: 'https://pnjawrhecnhz7lu7dcxwkedvsm.appsync-api.us-east-1.amazonaws.com/graphql',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_api_id: 'bkgwfg56rnhcpf3vhtkppo755a',
    aws_appsync_region: 'us-east-1',
    aws_appsync_apiKey: 'da2-q5l2fkepsfavxeezzy4o3ckyg4',
    oauth: {
        domain: 'auth.beluga.fyi',
        scope: ['email', 'openid'],
        redirectSignIn: 'https://beluga.fyi/',
        redirectSignOut: 'https://beluga.fyi/',
        responseType: 'code'
    }

}

export default config;