import React from 'react';
import {
  MDBFooter,
  MDBContainer,
  MDBIcon
} from 'mdb-react-ui-kit';
import Button from 'react-bootstrap/Button';

export default function Footer() {
  return (
    <MDBFooter className='text-center text-white footer'>
      <MDBContainer className='pt-8'>
        <section className='mb-8'>
        <Button
            variant='light'
            href='https://www.facebook.com/profile.php?id=100091859310049'
            role='button'
          >
            <MDBIcon fab className='fab fa-facebook-f' />
          </Button>

          <Button
            variant='light'
            href='https://twitter.com/belugafyi'
            role='button'
          >
            <MDBIcon fab className='fa-twitter' />
          </Button>

          <Button
            variant='light'
            href='https://www.instagram.com/belugafyi/'
            role='button'
          >
            <MDBIcon fab className='fa-instagram' />
          </Button>

        </section>
      </MDBContainer>
    </MDBFooter>
  );
}
