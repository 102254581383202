import React from 'react'
import './LoginSection.scss'

const LoginSection = ({ signIn, signUp }) => {
  return (
    <div className='signBox'>
      <div className='signLine'>Log in to leave a comment</div>
      <div>
          <button className='loginBtn' name='login' onClick={() => {signIn()}}>
            Log In
          </button>
      </div>
    </div>
  )
}

export default LoginSection