import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import {postComment, likeComment, dislikeComment, deleteComment, updateComment, createProperty, savePropertyToUser, deletePropertyFromUser} from '../appsync/mutations';
import {getCommentsForProperty, getProperty, getUser, getPropertySavedByUser, listPropertiesSavedByUser, getTrendingProperties, getUserNotifications} from '../appsync/queries';

class GraphQLClient {

    async postComment(input) {
        try {
            return await API.graphql(graphqlOperation(postComment, input));
        } catch(error) {
            console.error(error);
        }
    }
    async likeComment(pk) {
        try {
            const input = {
                pk
         };
            return await API.graphql(graphqlOperation(likeComment, input));
        } catch(error) {
            console.error(error);
        }
    }
    async dislikeComment(pk) {
        try {
            const input = {
                   pk
            };
            return await API.graphql(graphqlOperation(dislikeComment, input));
        } catch(error) {
            console.error(error);
        }
    }

    async listCommentsWithCognitoUser(input) {
        try {
            const response = await API.graphql(graphqlOperation(getCommentsForProperty, input));
            return response.data.listCommentsV0s.items;
        } catch(error) {
            console.error(error);
            return [];
        }
       
    }


    async listCommentsWithApiKey(input) {
        try {
            const response = await API.graphql({
                query: getCommentsForProperty,
                variables: input,
                authMode: GRAPHQL_AUTH_MODE.API_KEY
            });
            return response.data.listCommentsV0s.items;
        } catch(error) {
            console.error(error);
            return [];
        }
       
    }

    async deleteComment(pk) {
        try {
            const input = {
                pk
            };
            await API.graphql(graphqlOperation(deleteComment, input));
        } catch(error) {

        }
    }
    async updateComment(updatedComment) {
        try {
            const input = {
                pk: updatedComment.comId,
                comment: updatedComment.comment 
            };
            await API.graphql(graphqlOperation(updateComment, input));
        } catch(error) {

        }
    }

    async getProperty(pk) {
        const input = {
            pk
        }
        try {
            const response =  await API.graphql({
                query: getProperty,
                variables: input,
                authMode: GRAPHQL_AUTH_MODE.API_KEY
            });
            return response.data.getProperty;
        } catch(error) {
            console.error(error);
            return {};
        }
    }

    async createProperty(firstStreet, secondStreet, city, state, zipCode) {
        const input = {
            firstStreet,
            secondStreet,
            city,
            state,
            zipCode
        };
        const response = await API.graphql({
            query: createProperty,
            variables: input,
            authMode: GRAPHQL_AUTH_MODE.API_KEY
        });
    
        try {
          return response.data.createPropertyV0;
        } catch(error) {
            console.error(error);
            return {};
        }

    }

    async getUsernameData(sub) {
        const input = {
            pk: sub
        };
        try {
            const response = await API.graphql({
                query: getUser,
                variables: input,
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            })
            return response.data.getUsersV0.gsi_pk;
        } catch(error) {
            console.error(error)
            return {}
        }
    }

    async isPropertySavedByUser(sub, propertyId) {
        const input = {
            pk: `${sub}#${propertyId}`,
            sk: propertyId
        };
        try {
            const response = await API.graphql(graphqlOperation(getPropertySavedByUser, input));
            return response.data !== undefined &&
            response.data !== null &&
            response.data.getSavedPropertiesV0 !== undefined &&
            response.data.getSavedPropertiesV0 !== null &&
            response.data.getSavedPropertiesV0.pk !== undefined;
        } catch(e) {
        console.error(e);
        }  
    }
    async listPropertySavedByUser(sub) {
        const input = {
            gsi_pk: sub
        };
        try {
            const response = await API.graphql(graphqlOperation(listPropertiesSavedByUser, input));
            return response.data.listSavedPropertiesV0s.items;
        } catch(e) {
            console.error(e);
        }
    }

    async savePropertyToUser(sub, propertyId) {
        const input = {
            pk: `${sub}#${propertyId}`,
            sk: propertyId,
            gsi_pk: sub
        };
        try {
            await API.graphql(graphqlOperation(savePropertyToUser, input))
        } catch(e) {

        }
        
    }

    async deletePropertyToUser(sub, propertyId) {
        const input = {
            pk: `${sub}#${propertyId}`,
            sk: propertyId
        };
        try {
            await API.graphql(graphqlOperation(deletePropertyFromUser, input))
        } catch(e) {

        }
    }

    async getTrendingProperties() {
        try {
            // todo use next page here to allow users to scroll infinitely (when we have more comments)
            const response = await API.graphql({
                query: getTrendingProperties,
                authMode: GRAPHQL_AUTH_MODE.API_KEY
            });
            return response.data.getTrendingProperties.items;
        } catch(err) {
            console.log(err);
            return [];
        }
    }

    async getUnreadNotifications(userId) {
        return this.getUserNotifications(userId, 'UNREAD');
    }

    async getAllNotifications(userId) {
        return this.getUserNotifications(userId);
    }

    async getUserNotifications(userId, status = '') {
        const input = {
            userId,
            status
        }
        try {
            const response = await API.graphql({
                query: getUserNotifications,
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
                variables: input
            });

            return response.data.getNotificationsForUser.items;
        } catch(err) {
            console.log(err);
            return [];
        }
    }
}

export default GraphQLClient;