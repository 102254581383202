 /* eslint-disable */
import React from 'react'
import './InputField.scss'
import { useContext } from 'react'
import { Button } from 'react-bootstrap'
import { GlobalContext } from '../../context/Provider'

const RegularInput = ({
  formStyle,
  imgDiv,
  imgStyle,
  customImg,
  mode,
  inputStyle,
  cancelBtnStyle,
  comId,
  submitBtnStyle,
  handleSubmit,
  text,
  setText
}) => {
  const globalStore = useContext(GlobalContext)

  return (
    <div className='commentBlock'>
      <div className='userImg' style={imgDiv}>
          <img
            src={
              globalStore.customImg ||
              customImg ||
              globalStore.currentUserData.currentUserImg
            }
            style={globalStore.imgStyle || imgStyle}
            alt='userIcon'
            className='imgdefault'
          />
      </div>
        <textarea
          style={{
            width: '70%', 
            resize: 'none', 
            borderTop: 'none', 
            borderLeft: 'none', 
            borderRight: 'none',
            minHeight: '50px',
            maxHeight: '100px'
        }}
          placeholder='Comment Here'
          value={text}
          maxLength={500}
          onChange={(e) => setText(e.target.value)}
          onBlur={() => {
            try {
              window.document.body.style.zoom = 1;
            } catch(err) {
              console.error(err);
            }
          }}  
        />
      {mode && (
        <Button
          className='cancelBtn'
          variant='danger'
          style={{maxHeight: 'fit-content', margin: '1vh'}}
          onClick={() =>
            mode === 'editMode'
              ? globalStore.handleAction(comId, true)
              : globalStore.handleAction(comId, false)
          }
        >
          Cancel
        </Button>
      )}
      <Button
        className='postBtn'
        style={{maxHeight: 'fit-content', margin: '1vh'}}
        variant='success'
        disabled={text !== '' ? false : true}
        onClick={(e) => (text ? handleSubmit(e) : null)}
      >
        {mode ? 'Update' : 'Comment'}
      </Button>
    </div>
  )
}

export default RegularInput