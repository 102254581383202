export const postComment = `mutation CreateCommentsV1($comment: String!, $gsi_pk: String!, $username: String!) {
  createCommentsV1(input: {comment: $comment, gsi_pk: $gsi_pk, username: $username}) {
    pk
    sk
    gsi_pk
    datePosted
    comment
    likeCount
    dislikeCount
    username
  }
  }`;
  export const deleteComment = `mutation DeleteCommentsV1($pk: String!) {
    deleteCommentsV1(pk: $pk, sk: "COMMENT") {
      comment
    }
  }`;

  export const updateComment = `mutation UpdateCmmentsV0($pk: String!, $comment: String!) {
    updateCommentsV0(pk: $pk, sk: "COMMENT", comment: $comment) {
      comment
      datePosted
      updatedAt
    }
  }`;

  export const likeComment = `mutation LikeCommentV0($pk: String!) {
    likeCommentV0(pk: $pk, sk: "COMMENT") {
      likeCount
      dislikeCount
      comment
    }
  }`;

    export const dislikeComment = `mutation DislikeCommentV0($pk: String!) {
      dislikeCommentV0(pk: $pk, sk: "COMMENT") {
        likeCount
        dislikeCount
        comment
      }
    }`;

    export const createProperty = `mutation CreateProperty($city: String!, $firstStreet: String!, $secondStreet: String, $state: String!, $zipCode: Int!) {
      createPropertyV0(input: {city: $city, firstStreet: $firstStreet, secondStreet: $secondStreet, state: $state, zipCode: $zipCode})
    }
    `
    export const savePropertyToUser = `mutation CreateSavedPropertiesV0($pk: String!, $sk: String!, $gsi_pk: String!) {
      createSavedPropertiesV0(input: {pk: $pk, sk: $sk, gsi_pk: $gsi_pk}) {
        pk
        sk
      }
    }`

    export const deletePropertyFromUser = `mutation DeleteSavedPropertiesV0($pk: String!, $sk: String!) {
      deleteSavedPropertiesV0(input: {pk: $pk, sk: $sk}) {
        pk
        sk
      }
    }`